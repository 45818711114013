<template>
  <v-main class="main-wrapper">
    <loader :loading="$auth.loading" />
    <base-header :links="links" />
    <v-container>
      <v-row>
        <v-col>
          <slot />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import Loader from "@/components/base/Loader.vue";
import BaseHeader from "@/components/base/Header.vue";

export default {
  name: "External",
  components: {
    BaseHeader,
    Loader,
  },
  computed: {
    links() {
      if (this.$auth.isAuthenticated) {
        return [{ to: { name: "dashboard" }, text: this.$t("backToApp") }];
      }
      return [];
    },
  },
};
</script>

<style lang="scss">
.main-wrapper {
  background: #fafafa;
}
</style>
